import {
  Button,
  Image,
  Form,
  Header,
  Message,
  Modal,
  Divider,
} from "semantic-ui-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { states } from "../Common/states";
import { countries } from "../Common/countries";
import "./style.css";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
  RegionSelect,
  PhonecodeSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
const HangarInfo = (props) => {
  const [isModal, setModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [country, setCountry] = useState(null);
  const [theState, setTheState] = useState(null);
  const [city, setCity] = useState(null);
  const [language, setLanguage] = useState(null);
  const [isMobile, setMobile] = useState(false);
  const [errorFields, setErrorFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    userType: false,
    address1: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
    password: false,
    language: false,
  });
  const [contactObj, setContactObj] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    userType: "",
    theaterName: "",
    owningCircuit: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    message: "",
    password: "",
    language: "",
  });

  useEffect(() => {
    console.log(contactObj);
  }, [contactObj]);

  useEffect(() => {
    handleResizeEvent();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("no-scroll");
  }, []);

  const handleResizeEvent = () => {
    if (window.innerWidth < 500) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const handleChangeFieldString = (e, data) => {
    setError("");
    let key = data.id;
    let value = data.value;
    let errors = { ...errorFields };
    Object.assign(errors, { [key]: false });
    setErrorFields(errors);
    let obj = { ...contactObj };
    Object.assign(obj, { [key]: value });
    setContactObj(obj);
  };

  const handleChangeDropdown = (key, value) => {
    setError("");
    let errors = { ...errorFields };
    Object.assign(errors, { [key]: false });
    setErrorFields(errors);
    let obj = { ...contactObj };
    Object.assign(obj, { [key]: value });
    if (key === "country") {
      Object.assign(obj, { state: "", city: "", zipCode: "" });
      setTheState(null);
      setCity(null);
    }
    if (key === "state") {
      Object.assign(obj, { city: "", zipCode: "" });
      setCity(null);
    }
    if (key === "city") {
      Object.assign(obj, { zipCode: "" });
    }
    setContactObj(obj);
  };

  const handleSend = async () => {
    setSending(true);
    //validate data first
    const errors = { ...errorFields };
    if (!contactObj.firstName) {
      Object.assign(errors, { firstName: true });
    }
    if (!contactObj.lastName) {
      Object.assign(errors, { lastName: true });
    }
    if (!contactObj.email) {
      Object.assign(errors, { email: true });
    }
    if (!contactObj.phone) {
      Object.assign(errors, { phone: true });
    }
    if (!contactObj.userType) {
      Object.assign(errors, { userType: true });
    }
    if (!contactObj.address1) {
      Object.assign(errors, { address1: true });
    }
    if (!contactObj.city) {
      Object.assign(errors, { city: true });
    }
    if (!contactObj.state) {
      Object.assign(errors, { state: true });
    }
    if (!contactObj.zipCode) {
      Object.assign(errors, { zipCode: true });
    }
    if (!contactObj.country) {
      Object.assign(errors, { country: true });
    }
    if (!contactObj.password) {
      Object.assign(errors, { password: true });
    }
    if (!contactObj.language) {
      Object.assign(errors, { language: true });
    }
    if (!contactObj.message) {
      Object.assign(errors, { message: true });
    }
    setErrorFields(errors);
    if (errors.firstName || errors.lastName || errors.email || errors.message) {
      setSending(false);
      return;
    }
    try {
      const { data } = await axios.post(
        "https://tower.paperairmedia.com/api/infoRequest",
        {
          firstName: contactObj.firstName,
          lastName: contactObj.lastName,
          email: contactObj.email,
          phone: contactObj.phone,
          userType: contactObj.userType,
          theaterName: contactObj.theaterName,
          owningCircuit: contactObj.owningCircuit,
          address1: contactObj.address1,
          address2: contactObj.address2,
          city: contactObj.city,
          state: contactObj.state,
          zipCode: contactObj.zipCode,
          country: contactObj.country,
          message: contactObj.message,
          password: contactObj.password,
          language: contactObj.language,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data && data.message === "Sent") {
        setSuccess(true);
        setSending(false);
        setModal(true);
      } else {
        // something went wrong, eventually need more error handling than this
        setSending(false);
        setError("Error processing request.");
      }
    } catch (error) {
      console.log(error);
      setSending(false);
      setError(error.message);
    }
  };

  const handleDismiss = () => {
    setSuccess(false);
    setModal(false);
    setContactObj({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      userType: "",
      theaterName: "",
      owningCircuit: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      message: "",
      password: "",
      language: "",
    });
    setError("");
  };

  return (
    <div
      className="animate__animated animate__fadeIn animate__slower"
      id="home-container"
    >
      <div
        style={{
          width: "100%",
          height: "calc(100vh)",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          backgroundImage: "url(./images/home-hero-1.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        className="wrap-section"
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            width: "150px",
            cursor: "pointer",
          }}
          onClick={() => (window.location.href = "/")}
        >
          <Image src="/images/logo-white.png" />
        </div>
        <div
          className="mobile-scroll"
          style={{
            backgroundColor: "#F1F1F1",
            padding: "20px",
            borderRadius: isMobile ? "" : "20px",
            height: isMobile ? "100dvh" : "90dvh",
            overflow: "auto",
            zIndex: 1,
            width: "100%",
            maxWidth: "700px",
            position: isMobile ? "absolute" : "",
            top: isMobile ? "0px" : "",
          }}
        >
          <Header
            style={{ textAlign: "center" }}
            content="The Hangar Global"
            subheader="Information Request"
          />
          <Form size="large">
            <Form.Group widths={"equal"}>
              <Form.Input
                error={errorFields.firstName}
                required
                id="firstName"
                label="First Name"
                value={contactObj.firstName}
                placeholder="First Name"
                onChange={handleChangeFieldString}
              />
              <Form.Input
                error={errorFields.lastName}
                required
                id="lastName"
                label="Last Name"
                value={contactObj.lastName}
                placeholder="Last Name"
                onChange={handleChangeFieldString}
              />
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Input
                error={errorFields.phone}
                required
                id="phone"
                label="Phone"
                value={contactObj.phone}
                placeholder="Phone"
                onChange={handleChangeFieldString}
              />
              <Form.Input
                error={errorFields.email}
                required
                id="email"
                label="Email"
                value={contactObj.email}
                placeholder="Email"
                onChange={handleChangeFieldString}
              />
            </Form.Group>
            <Form.Select
              error={errorFields.userType}
              options={[
                { text: "Booking Agent", value: "Booking Agent" },
                { text: "Circuit Head Office", value: "Circuit Head Office" },
                { text: "Cinema", value: "Cinema" },
                { text: "Other", value: "Other" },
              ]}
              required
              id="userType"
              label="User Type"
              value={contactObj.userType}
              placeholder="Select type..."
              onChange={handleChangeFieldString}
            />
            <Form.Group widths={"equal"}>
              <Form.Input
                error={errorFields.theaterName}
                id="theaterName"
                label="Cinema Name"
                value={contactObj.theaterName}
                placeholder="e.g. Cineworld London Leicester Square"
                onChange={handleChangeFieldString}
              />
              <Form.Input
                error={errorFields.owningCircuit}
                id="owningCircuit"
                label="Owning Circuit"
                value={contactObj.owningCircuit}
                placeholder="e.g.  Cineworld, Odeon, Vue, Showcase, etc…"
                onChange={handleChangeFieldString}
              />
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Input
                required
                error={errorFields.address1}
                id="address1"
                label="Street Address"
                value={contactObj.address1}
                placeholder="Theater or circuit address"
                onChange={handleChangeFieldString}
              />
              <Form.Input
                id="address2"
                label="Suite/Apt#"
                value={contactObj.address2}
                placeholder="Suite 101"
                onChange={handleChangeFieldString}
              />
            </Form.Group>
            <Divider section />
            <Form.Field required>
              <label>Language</label>
              <LanguageSelect
                error={errorFields.language}
                containerClassName="form-group"
                inputClassName="ui input menu transition"
                onChange={(lang) => {
                  setLanguage(lang);
                  handleChangeDropdown("language", lang.name);
                }}
                defaultValue={language}
                placeHolder="Select Language"
              />
            </Form.Field>
            <Form.Field required>
              <label>Country</label>
              <CountrySelect
                error={errorFields.country}
                placeHolder="Select Country"
                onChange={(country) => {
                  setCountry(country);
                  handleChangeDropdown("country", country.name);
                }}
              />
            </Form.Field>

            <Form.Field required>
              <label>State</label>
              <StateSelect
                error={errorFields.state}
                disabled={!country?.id}
                countryid={country?.id}
                onChange={(state) => {
                  setTheState(state);
                  handleChangeDropdown("state", state.name);
                }}
                defaultValue={theState}
                placeHolder="Select State"
              />
            </Form.Field>

            <Form.Field required>
              <label>City</label>
              <CitySelect
                error={errorFields.city}
                inputClassName="ui input menu transition"
                placeHolder="Select City"
                countryid={country?.id}
                stateid={theState?.id}
                disabled={!theState?.id}
                onChange={(city) => {
                  setCity(city);
                  handleChangeDropdown("city", city.name);
                }}
                defaultValue={city}
              />
            </Form.Field>

            <Form.Input
              required
              error={errorFields.zipCode}
              id="zipCode"
              label="Zip / Postal Code"
              value={contactObj.zipCode}
              placeholder="Zip Code"
              onChange={handleChangeFieldString}
            />

            <Form.Group widths={"equal"}>
              {/* <Form.Input
                required
                error={errorFields.city}
                id="city"
                label="City"
                value={contactObj.city}
                placeholder="City"
                onChange={handleChangeFieldString}
              /> */}

              {/* <Form.Select
                error={errorFields.state}
                required
                search
                id="state"
                label="State"
                value={contactObj.state}
                placeholder="Select State"
                onChange={handleChangeFieldString}
                options={states}
              /> */}
            </Form.Group>
            <Divider section />
            <Form.Input
              required
              error={errorFields.password}
              id="password"
              label="Preferred Password"
              value={contactObj.password}
              placeholder="This will be your password once the account is approved."
              onChange={handleChangeFieldString}
            />
            <Form.TextArea
              error={errorFields.message}
              id="message"
              label="Message"
              value={contactObj.message}
              placeholder="Enter your message..."
              onChange={handleChangeFieldString}
            />
            {(errorFields.firstName ||
              errorFields.lastName ||
              errorFields.email ||
              errorFields.message) && (
              <Message
                visible
                error
                content="Please fill out all required fields."
              />
            )}
            {error && <Message error content={error} />}
            {success && (
              <Message positive content="Request Sent Successfully!" />
            )}
            <Button
              loading={sending}
              disabled={sending || success}
              size="large"
              positive
              content="Submit"
              fluid
              onClick={handleSend}
            />
          </Form>
        </div>
      </div>
      {success && (
        <Modal open={isModal} size="tiny">
          <Message
            positive
            content="Thank you! Your message has been sent to the PaperAirplane team."
            onDismiss={handleDismiss}
          />
        </Modal>
      )}
    </div>
    // <Modal open size="small">
    //   <Modal.Header style={{ backgroundColor: "#4682CC" }}>
    //     <Header inverted>
    //       Contact Us
    //       <Header.Subheader>
    //         Please fill out the form below to inquire about a collaboration, or
    //         simply say hello.
    //       </Header.Subheader>
    //     </Header>
    //   </Modal.Header>
    //   <Modal.Content style={{ backgroundColor: "#F1F1F1" }}>

    //   </Modal.Content>
    //   <Modal.Actions
    //     style={{ display: "flex", justifyContent: "space-between" }}
    //   >
    //     <Button size="large" content="Cancel" onClick={() => setModal(false)} />
    //     <Button
    //       size="large"
    //       primary
    //       content="Send"
    //       onClick={handleSend}
    //       loading={sending}
    //       disabled={sending}
    //     />
    //   </Modal.Actions>
    //   {success && (
    //     <Modal open size="tiny">
    //       <Message
    //         positive
    //         content="Thank you! Your message has been sent to the PaperAirplane team."
    //         onDismiss={handleDismiss}
    //       />
    //     </Modal>
    //   )}
    // </Modal>
  );
};
export default HangarInfo;
